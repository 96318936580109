var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Loader'),_c('div',{staticClass:"wrapper"},[_c('NavBarStyle4',{attrs:{"title":"Dashboard","homeURL":{ name: 'dashboard1.home' },"logo":_vm.logo}}),(
      (this.$route.name == 'social.friend-list' ||
       this.$route.name == 'social.group' ||
       this.$route.name == 'social.profile-image'  ||
       this.$route.name == 'social.profile-video' ||
        this.$route.name == 'social.profile-event' ||
        this.$route.name == 'social.birthday' ||
        this.$route.name == 'social.weather' ||
        this.$route.name == 'social.sign-profile-detail' ||
        this.$route.name == 'app.calendar'
        )
      )?_c('div',{staticClass:"header-for-bg"}):_vm._e(),_vm._m(0),_c('div',{staticClass:"content-page",attrs:{"id":"content-page"}},[_c('div',{staticClass:"container"},[_c('router-view')],1)])],1),_c('FooterStyle1',{staticClass:"iq-footer",scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('p',{staticClass:"m-0 font-size-12"},[_vm._v("© Copyright 2021 "),_c('a',{attrs:{"href":"https://womenfirstdigital.org/","target":"_blank"}},[_vm._v("Womenfirstdigital.org")]),_vm._v(" All Rights Reserved.")])]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-header position-relative"},[_c('img',{staticClass:"img-fluid w-100",attrs:{"src":require("../assets/images/login/form-banner.svg"),"alt":"header-bg"}}),_c('div',{staticClass:"title-on-header"},[_c('div',{staticClass:"data-block"})])])}]

export { render, staticRenderFns }